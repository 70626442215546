import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <div className="blog-post-container">
        <div className="blog-post">
          <h1 style={{ marginBottom: "0", marginTop: "0" }}>
            {frontmatter.title}
          </h1>
          <h4
            style={{
              color: "#ffffff75",
              marginBottom: "0.5em",
              marginTop: "0.25em",
            }}
          >
            - विश्वनाथ 'प्रेम'
          </h4>
          <hr style={{ borderColor: "white" }} />
          <div
            style={{ fontSize: "1.1em" }}
            className="blog-post-content text-white"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
      fileAbsolutePath
    }
  }
`
